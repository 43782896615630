<template>
  <div class="content_body PostponeApply" v-loading="loading">
    <div class="nav_header">
      <el-row>
        <el-col :span="20">
          <el-form :inline="true" size="small" @keyup.enter.native="handleSearch">
            <el-form-item label="单据号">
              <el-input v-model="PostponebillID" placeholder="输入单据号" clearable @clear="handleSearch"></el-input>
            </el-form-item>
            <el-form-item label="客户">
              <el-input v-model="Name" placeholder="名字、手机号或客户编号" clearable @clear="handleSearch"></el-input>
            </el-form-item>
            <el-form-item label="申请时间">
              <el-date-picker @clear="handleSearch" @change="handleSearch" v-model="SearchData" :picker-options="pickerOptions" unlink-panels type="daterange" range-separator="至" value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
            </el-form-item>

            <el-form-item label="状态">
              <el-select @change="handleSearch" @clear="handleSearch" v-model="isAllowSell" placeholder="请选择审批状态" clearable>
                <!-- <el-option label="草稿" :value="10"></el-option> -->
                <el-option label="待审批" :value="20"></el-option>
                <el-option label="已同意" :value="30"></el-option>
                <el-option label="已驳回" :value="40"></el-option>
                <el-option label="已撤销" :value="50"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="handleSearch" v-prevent-click>搜索</el-button>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="4" class="text_right">
          <el-button type="primary" size="small" @click="showAddApplyDialog" v-prevent-click>延期申请</el-button>
        </el-col>
      </el-row>
    </div>

    <div class="martp_10">
      <el-table size="small" :data="tableData" tooltip-effect="light">
        <el-table-column prop="ID" label="单据号"></el-table-column>
        <el-table-column prop="CustomerName" label="客户信息">
          <template slot-scope="scope">
            <div>{{scope.row.CustomerName}}</div>
            <div>手机号：{{scope.row.PhoneNumber}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="ApprovalStatus" label="审批状态">
          <template slot-scope="scope">{{scope.row.ApprovalStatus == 10?"草稿":scope.row.ApprovalStatus == 20?"待审批" :scope.row.ApprovalStatus == 30?"已同意":scope.row.ApprovalStatus ==40 ?"已驳回":'已撤销'}}</template>
        </el-table-column>
        <el-table-column prop="CreatedOn" label="申请时间"></el-table-column>
        <el-table-column prop="CreatedByName" label="申请人"></el-table-column>
        <el-table-column prop="PostponeRemark" label="备注信息" show-overflow-tooltip></el-table-column>
        <el-table-column label="操作" width="80px">
          <template slot-scope="scope">
            <el-button type="primary" size="small" @click="showApplyDetailDialog(scope.row)" v-prevent-click>详情</el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="text_right pad_15">
        <el-pagination background v-if="paginations.total > 0" @current-change="handleCurrentChange" :current-page.sync="paginations.page" :page-size="paginations.page_size" :layout="paginations.layout" :total="paginations.total"></el-pagination>
      </div>
    </div>

    <!-- 添加延期申请 -->
    <el-dialog :visible.sync="addApplyVisible" title="延期申请" width="1300px" :close-on-click-modal="false">
      <div>
        <el-row class="project_content border_bottom">
          <el-col :span="10" class="border_right" style="padding:15px 10px 15px 0px">
            <el-autocomplete popper-class="customer-autocomplete" prefix-icon="el-icon-user-solid" v-model="customerName" style="width:100%" size="small" placeholder="请输入客户姓名、手机号、编号查找" :fetch-suggestions="saleCustomerData" @select="handleCustomerSelect" :disabled="customerID!=null" :trigger-on-focus="false" :hide-loading="false" :highlight-first-item="true" :select-when-unmatched="true">
              <template slot="append">
                <el-button icon="el-icon-delete" @click="removeCustomer"></el-button>
              </template>
              <template slot-scope="{ item }">
                <div class="name">
                  {{ item.Name}}
                  <el-tag size="mini" v-if="item.CustomerLevelName">{{ item.CustomerLevelName }}</el-tag>
                </div>
                <div class="info">手机号：{{ item.PhoneNumber }}</div>
                <span class="info" v-if="item.Code">客户编号：{{ item.Code }}</span>
              </template>
            </el-autocomplete>
          </el-col>
          <el-col :span="14" class="project_right" style="padding:22px 10px">
            <el-row :gutter="10" class="font_14">
              <el-col :span="24">
                <el-col :span="9">商品</el-col>
                <el-col :span="5">原失效日期</el-col>
                <el-col :span="8">新失效日期</el-col>
                <el-col :span="2" class="text_right">操作</el-col>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row class="project_content">
          <el-col :span="10" class="project_left">
            <div style="padding-right:10px">
              <el-input class="martp_15" placeholder="请输入商品名称、别名关键字，按回车搜索" v-model="customerAlias" size="small" clearable @clear="searchGoodsClick" @keyup.enter.native="searchGoodsClick">
                <i slot="suffix" @click="searchGoodsClick" class="el-input__icon el-icon-search"></i>
              </el-input>
            </div>

            <el-tabs v-model="tabPane" style="height:56vh" class="martp_10 postponeGoods">
              <el-tab-pane label="储值卡" name="0" v-if="SavingCardList.length>0">
                <el-scrollbar class="el-scrollbar_height">
                  <el-card class="marbm_10 marrt_10" :class="index==0?'martp_10':''" :body-style="{ padding: '0px' }" shadow="hover" v-for="(item,index) in SavingCardList" :key="index" @click.native="clickItemConsume(item,2)">
                    <div slot="header">
                      <span>{{item.Name}}</span>
                      <span v-if="item.Alias">({{item.Alias}})</span>
                    </div>
                    <el-row class="border_bottom">
                      <el-col :span="8" class="border_right">
                        <div class="goods-item">
                          <span class="goods-lable">有效余额：</span>
                          <span>¥ {{item.ValidBalance | NumFormat}}</span>
                          <!-- <el-popover class="marlt_5" placement="top-start" width="240" trigger="hover">
                            <p>1.有效金额= 剩余数量-退款中金额-欠款金额；</p>
                            <p>2.注意：如果有赠额的情况，赠额可用金额也跟退款金额和欠款金额有关；</p>
                            <p>3.比如：充值100送100，欠款50，则可用本金为50，可用赠额为50，可用余额为100。</p>
                            <el-button type="text" style="color:#dcdfe6" class="font_12 el-popover-botton-tip" icon="el-icon-info" slot="reference"></el-button>
                          </el-popover> -->
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div class="goods-item">
                          <span class="goods-lable">剩余金额：</span>
                          <span>¥ {{item.TotalBalance | NumFormat}}</span>
                          <!-- <el-popover class="marlt_5" placement="top-start" width="240" trigger="hover">
                            <p>剩余金额= 剩余本金+剩余赠额</p>
                            <el-button type="text" style="color:#dcdfe6" class="font_12 el-popover-botton-tip" icon="el-icon-info" slot="reference"></el-button>
                          </el-popover> -->
                        </div>
                      </el-col>
                      <el-col :span="8" class="border_left">
                        <div class="goods-item">
                          <span class="goods-lable">退款中金额：</span>
                          <span>¥ {{item.RefundAmount | NumFormat}}</span>
                        </div>
                      </el-col>
                    </el-row>
                    <el-col :span="8" class="border_right">
                      <div class="goods-item">
                        <span class="goods-lable">购买金额：</span>
                        <span>{{item.TotalAmount | NumFormat}}</span>
                      </div>
                    </el-col>
                    <el-row class="border_bottom">
                      <el-col :span="8">
                        <div class="goods-item">
                          <span class="goods-lable">剩余本金：</span>
                          <span>¥ {{item.Balance | NumFormat}}</span>
                        </div>
                      </el-col>
                      <el-col :span="8" class="border_left">
                        <div class="goods-item">
                          <span class="goods-lable">剩余赠额：</span>
                          <span>¥ {{item.LargessBalance | NumFormat}}</span>
                        </div>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="8" class="border_right">
                        <div class="goods-item">
                          <span class="goods-lable">欠款金额：</span>
                          <span>¥ {{item.ArrearAmount | NumFormat}}</span>
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div class="goods-item">
                          <span class="goods-lable">购买日期：</span>
                          <span>{{item.BuyDate}}</span>
                        </div>
                      </el-col>
                      <el-col :span="8" class="border_left">
                        <div class="goods-item">
                          <span class="goods-lable">有效期：</span>
                          <span>{{item.ValidDayName}}</span>
                        </div>
                      </el-col>
                    </el-row>
                  </el-card>

                  <!-- <el-card
                    class="marbm_10  marrt_10 cursor_pointer"
                    :class="index==0?'martp_10':''"
                    :body-style="{ padding: '0px' }"
                    shadow="hover"
                    v-for="(item,index) in SavingCardList"
                    :key="index+'saveingcard'"
                    @click.native="clickItemConsume(item,2,)"                 
                   >
                    <el-col :span="24" class="pad_10_15 border_bottom" style="background-color:#F5F7FA;">{{item.Name}}
                      <span v-if="item.Alias">({{item.Alias}})</span>
                      <el-tag v-if="item.IsLargess" size="mini" type="danger" class="marlt_5">赠</el-tag>
                    </el-col>
                    <el-col :span="24" class="font_13">
                      <el-row>
                        <el-row class="border_bottom">
                          <el-col :span="8" class="col_border">可用余额：<span class="font_color">￥{{item.ValidBalance | NumFormat}}</span> </el-col>
                           <el-col :span="8" class="col_border">剩余金额：￥<span class="font_color">{{item.TotalBalance | NumFormat}}</span></el-col>
                           <el-col :span="8" class="col_border dis_flex flex_dir_row" style="border-right:0px">
                            退款中金额：
                            <div class="font_color">￥{{item.RefundAmount | NumFormat}}</div>
                          </el-col>
                        </el-row>
                        <el-row class="border_bottom">
                           <el-col :span="8" class="col_border">剩余本金： <span class="font_color">￥{{item.Balance | NumFormat}}</span></el-col>
                          <el-col :span="8" class="col_border dis_flex flex_dir_row">
                            剩余赠额：
                            <div class="font_color">￥{{item.LargessBalance | NumFormat}}</div>
                          </el-col>
                          <el-col :span="8" class="col_border"  style="border-right:0px">有效期至：<span class="font_color">{{item.ValidDayName}}</span></el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="8" class="col_border dis_flex flex_dir_row">
                            欠款金额：
                            <div class="font_color">￥{{item.ArrearAmount | NumFormat}}</div>
                          </el-col>
                           <el-col :span="16" class="col_border"  style="border-right:0px">购买日期: <span class="font_color"> {{item.BuyDate}}</span></el-col>
                        </el-row>
                      </el-row>
                    </el-col>
                  </el-card>-->
                </el-scrollbar>
              </el-tab-pane>
              <el-tab-pane label="时效卡" name="2" v-if="TimeCardList.length>0">
                <el-scrollbar class="el-scrollbar_height">
                  <el-card class="marbm_10 marrt_10 cursor_pointer" :class="index==0?'martp_10':''" :body-style="{ padding: '0px' }" shadow="hover" v-for="(item,index) in TimeCardList" :key="index" @click.native="clickItemConsume(item,4)">
                    <div slot="header">
                      <span>{{item.Name}}</span>
                      <span v-if="item.Alias">({{item.Alias}})</span>
                      <el-tag v-if="item.IsLargess" size="mini" class="marlt_5" type="danger">赠</el-tag>
                      <el-tag v-if="item.IsRefund" size="mini" class="marlt_5" type="danger">退款处理中</el-tag>
                    </div>
                    <el-row class="border_bottom">
                      <el-col :span="8" class="border_right">
                        <div class="goods-item">
                          <span class="goods-lable">周期内消耗次数：</span>
                          <span>{{item.ConsumeCycleAmount}}</span>
                          <!-- <el-popover class="marlt_5" placement="top-start" width="240" trigger="hover">
                            <p>当“周期内消耗次数”超过“消耗周期限制”，则不能使用。</p>
                            <el-button type="text" style="color:#dcdfe6" class="font_12 el-popover-botton-tip" icon="el-icon-info" slot="reference"></el-button>
                          </el-popover> -->
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div class="goods-item">
                          <span class="goods-lable">消耗周期限制：</span>
                          <span>{{(item.ConsumeCycle == 0 || item.CycleLimitAmount ==0)? "不限制":item.CycleLimitAmount + '(次)/' + item.ConsumeCycle + '(天)' }}</span>
                        </div>
                      </el-col>
                      <el-col :span="8" class="border_left">
                        <div class="goods-item">
                          <span class="goods-lable">累计耗用次数：</span>
                          <span>{{item.ConsumeAmount}}</span>
                        </div>
                      </el-col>
                    </el-row>
                    <el-row class="border_bottom">
                      <el-col :span="8" class="border_right">
                        <div class="goods-item">
                          <span class="goods-lable">购买金额：</span>
                          <span v-if="item.IsLargess">¥ 0.00</span>
                          <span v-else>¥ {{item.TotalAmount | NumFormat}}</span>
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div class="goods-item">
                          <span class="goods-lable">业绩提成次数：</span>
                          <span>{{item.PerformanceBalance}}</span>
                          <!-- <el-popover class="marlt_5" placement="top-start" width="240" trigger="hover">
                            <p>用于确认员工消耗业绩。</p>
                            <p>比如：购买金额为1000(如果卡为赠送，则按照售卖价格)，业绩提成次数为10，则每次使用时效卡耗做项目，则1~10次时，员工业绩为100，11次以后（包含第11次），员工业绩为0。</p>
                            <el-button type="text" style="color:#dcdfe6" class="font_12 el-popover-botton-tip" icon="el-icon-info" slot="reference"></el-button>
                          </el-popover> -->
                        </div>
                      </el-col>
                      <el-col :span="8" class="border_left">
                        <div class="goods-item">
                          <span class="goods-lable">参考单价：</span>
                          <span>¥ {{item.Amount | NumFormat}}</span>
                        </div>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="8" class="border_right">
                        <div class="goods-item">
                          <span class="goods-lable">欠款金额：</span>
                          <span>¥ {{item.ArrearAmount | NumFormat}}</span>
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div class="goods-item">
                          <span class="goods-lable">购买日期：</span>
                          <span>{{item.BuyDate}}</span>
                        </div>
                      </el-col>
                      <el-col :span="8" class="border_left">
                        <div class="goods-item">
                          <span class="goods-lable">有效期：</span>
                          <span>{{item.ValidDayName }}</span>
                        </div>
                      </el-col>
                    </el-row>
                  </el-card>

                  <!-- <el-card
                    class="marbm_10 marrt_10 cursor_pointer"
                    :class="index==0?'martp_10':''"
                    :body-style="{ padding: '0px' }"
                    shadow="hover"
                    v-for="(item,index) in TimeCardList"
                    :key="index+'time'"
                    @click.native="clickItemConsume(item,4)"
                  >
                    <el-col
                      :span="24"
                      class="pad_10_15 border_right border_left"
                      style="background-color:#F5F7FA;"
                    >
                      {{item.Name}}
                      <span v-if="item.Alias">({{item.Alias}})</span>
                      <el-tag v-if="item.IsLargess" size="mini" class="marlt_5" type="danger">赠</el-tag>
                    </el-col>
                    <el-col :span="24">
                      <el-row class="border">
                        <el-row class="border_bottom">
                          <el-col :span="8" class="col_border">
                            已耗用次数：
                            <span class="font_color">{{item.ConsumeAmount}}</span>
                          </el-col>
                          <el-col :span="8" class="col_border">
                            业绩次数：
                            <span class="font_color">{{item.PerformanceTimes}}</span>
                          </el-col>
                          <el-col :span="8" class="col_border" style="border-right:0px">
                            周期内消耗次数：
                            <span class="font_color">{{item.ConsumeCycleAmount}}</span>
                          </el-col>
                        </el-row>
                        <el-row class="border_bottom">
                          <el-col :span="8" class="col_border dis_flex flex_dir_row">
                            欠款金额：
                            <div class="font_color">￥{{item.ArrearAmount | NumFormat}}</div>
                          </el-col>
                          <el-col :span="8" class="col_border">
                            卡总金额：
                            <span class="font_color">￥{{item.TotalAmount | NumFormat}}</span>
                          </el-col>
                          <el-col :span="8" class="col_border" style="border-right:0px">
                            参考单价：
                            <span class="font_color">￥{{item.Price | NumFormat}}</span>
                          </el-col>
                        </el-row>
                        <el-row class>
                          <el-col :span="8" class="col_border">
                            消耗周期：
                            <span
                              class="font_color"
                            >{{(item.ConsumeCycle == 0 || item.CycleLimitAmount ==0)? "不限制":item.ConsumeCycle + '天/' + item.CycleLimitAmount + '次' }}</span>
                          </el-col>
                          <el-col :span="8" class="col_border">
                            购买时间：
                            <span class="font_color">{{item.BuyDate}}</span>
                          </el-col>
                          <el-col :span="8" class="col_border" style="border-right:0px">
                            有效期至：
                            <span class="font_color">{{item.ValidDayName}}</span>
                          </el-col>
                        </el-row>
                      </el-row>
                    </el-col>
                  </el-card>-->
                </el-scrollbar>
              </el-tab-pane>
              <el-tab-pane label="通用次卡" name="3" v-if="GeneralCardList.length>0">
                <el-scrollbar class="el-scrollbar_height">
                  <el-card class="marbm_10  marrt_10 cursor_pointer" :class="index==0?'martp_10':''" :body-style="{ padding: '0px' }" shadow="hover" v-for="(item,index) in GeneralCardList" :key="index" @click.native="clickItemConsume(item,5)">
                    <div slot="header">
                      <span>{{item.Name}}</span>
                      <span v-if="item.Alias">({{item.Alias}})</span>
                      <el-tag v-if="item.IsLargess" size="mini" class="marlt_5" type="danger">赠</el-tag>
                    </div>
                    <el-row class="border_bottom">
                      <el-col :span="8" class="border_right">
                        <div class="goods-item">
                          <span class="goods-lable">有效次数：</span>
                          <span>{{item.ValidBalance}}</span>
                          <!-- <el-popover class="marlt_5" placement="top-start" width="240" trigger="hover">
                            <p>有效次数= 剩余数量-退款中数量-欠款占用的数量[欠款金额÷参考单价(向上取整)]</p>
                            <el-button type="text" style="color:#dcdfe6" class="font_12 el-popover-botton-tip" icon="el-icon-info" slot="reference"></el-button>
                          </el-popover> -->
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div class="goods-item">
                          <span class="goods-lable">剩余次数：</span>
                          <span>{{item.Balance}}</span>
                        </div>
                      </el-col>
                      <el-col :span="8" class="border_left">
                        <div class="goods-item">
                          <span class="goods-lable">退款中数量：</span>
                          <span>{{item.RefundAmount}}</span>
                        </div>
                      </el-col>
                    </el-row>
                    <el-row class="border_bottom">
                      <el-col :span="8" class="border_right">
                        <div class="goods-item">
                          <span class="goods-lable">购买金额：</span>
                          <span v-if="item.IsLargess">¥ 0.00</span>
                          <span v-else>¥ {{item.TotalAmount | NumFormat}}</span>
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div class="goods-item">
                          <span class="goods-lable">卡总次数：</span>
                          <span>{{item.CardTimes}}</span>
                        </div>
                      </el-col>
                      <el-col :span="8" class="border_left">
                        <div class="goods-item">
                          <span class="goods-lable">参考单价：</span>
                          <span>¥ {{item.Amount | NumFormat}}</span>
                        </div>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="8" class="border_right">
                        <div class="goods-item">
                          <span class="goods-lable">欠款金额：</span>
                          <span>¥ {{item.ArrearAmount | NumFormat}}</span>
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div class="goods-item">
                          <span class="goods-lable">购买日期：</span>
                          <span>{{item.BuyDate}}</span>
                        </div>
                      </el-col>
                      <el-col :span="8" class="border_left">
                        <div class="goods-item">
                          <span class="goods-lable">有效期：</span>
                          <span>{{item.ValidDayName}}</span>
                        </div>
                      </el-col>
                    </el-row>
                  </el-card>

                  <!-- <el-card
                    class="marbm_10 marrt_10 cursor_pointer"
                    :class="index==0?'martp_10':''"
                    :body-style="{ padding: '0px' }"
                    shadow="hover"
                    v-for="(item,index) in GeneralCardList"
                    :key="index +'gen'"
                    @click.native="clickItemConsume(item,5)"
                  >
                    <el-col
                      :span="24"
                      class="pad_10_15 border_right border_left"
                      style="background-color:#F5F7FA;"
                    >
                      {{item.Name}}
                      <span v-if="item.Alias">({{item.Alias}})</span>
                      <el-tag v-if="item.IsLargess" size="mini" class="marlt_5" type="danger">赠</el-tag>
                    </el-col>
                    <el-col :span="24">
                      <el-row class="border">
                        <el-row class="border_bottom">
                          <el-col :span="8" class="col_border">
                            有效次数：
                            <span class="font_color">{{item.ValidBalance}}</span>
                          </el-col>
                          <el-col :span="8" class="col_border">
                            剩余次数：
                            <span class="font_color">{{item.Balance}}</span>
                          </el-col>
                          <el-col :span="8" class="col_border" style="border-right:0px">
                            卡总次数：
                            <span class="font_color">{{item.CardTimes}}</span>
                          </el-col>
                        </el-row>
                        <el-row class="border_bottom">
                          <el-col :span="8" class="col_border">
                            卡总金额：
                            <span class="font_color">￥{{item.TotalAmount | NumFormat}}</span>
                          </el-col>
                          <el-col :span="8" class="col_border flex_dir_row">
                            欠款金额：
                            <span class="font_color">￥{{item.ArrearAmount| NumFormat}}</span>
                          </el-col>
                          <el-col :span="8" class="col_border" style="border-right:0px">
                            参考单价：
                            <span class="font_color">￥{{item.Price | NumFormat}}</span>
                          </el-col>
                        </el-row>
                        <el-row class>
                          <el-col :span="8" class="col_border dis_flex flex_dir_row">
                            退款中金额：
                            <div class="font_color">￥{{item.RefundAmount | NumFormat}}</div>
                          </el-col>
                          <el-col :span="8" class="col_border">
                            购买时间：
                            <span class="font_color">{{item.BuyDate}}</span>
                          </el-col>
                          <el-col :span="8" class="col_border" style="border-right:0px">
                            有效期至：
                            <span class="font_color">{{item.ValidDayName}}</span>
                          </el-col>
                        </el-row>
                      </el-row>
                    </el-col>
                  </el-card> -->
                </el-scrollbar>
              </el-tab-pane>
              <el-tab-pane label="套餐卡" name="1" v-if="PackageCardList.length>0">
                <el-scrollbar class="el-scrollbar_height">
                  <el-card class="marbm_10 marrt_10 cursor_pointer" :class="index==0?'martp_10':''" :body-style="{ padding: '0px' }" v-for="(item,index) in PackageCardList" shadow="hover" @click.native="clickItemConsume(item,3)" :key="index">
                    <div slot="header">
                      <span>{{item.Name}}</span>
                      <span v-if="item.Alias">({{item.Alias}})</span>
                      <el-tag v-if="item.IsLargess" size="mini" class="marlt_5" type="danger">赠</el-tag>
                    </div>
                    <el-row class="border_bottom">
                      <el-col :span="8" class="border_right">
                        <div class="goods-item">
                          <span class="goods-lable">购买价格：</span>
                          <span>{{item.Price | NumFormat}}</span>
                        </div>
                      </el-col>
                      <el-col :span="8" class="border_right">
                        <div class="goods-item">
                          <span class="goods-lable">欠款金额：</span>
                          <span>{{item.ArrearAmount | NumFormat}}</span>
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div class="goods-item">
                          <span class="goods-lable">购买日期：</span>
                          <span>{{item.BuyDate}}</span>
                        </div>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="8" class="border_left">
                        <div class="goods-item">
                          <span class="goods-lable">有效期：</span>
                          <span>{{item.ValidDayName}}</span>
                        </div>
                      </el-col>
                    </el-row>
                  </el-card>

                  <!-- <el-card
                    class="marbm_10 marrt_10 cursor_pointer"
                    :class="index==0?'martp_10':''"
                    :body-style="{ padding: '0px' }"
                    shadow="hover"
                    v-for="(item,index) in PackageCardList"
                    :key="index +'pckList'"
                    @click.native="clickItemConsume(item,3)"
                  >
                    <el-col :span="24" class="pad_10_15" style="background-color:#F5F7FA;">
                      {{item.Name}}
                      <span v-if="item.Alias">({{item.Alias}})</span>
                      <el-tag v-if="item.IsLargess" size="mini" class="marlt_5" type="danger">赠</el-tag>
                    </el-col>
                    <el-col :span="24">
                      <el-row class="border">
                        <el-row class="border_bottom">
                          <el-col :span="8" class="col_border">已耗用次数： {{item.ConsumeAmount}}</el-col>
                          <el-col :span="8" class="col_border">业绩次数： {{item.PerformanceTimes}}</el-col>
                          <el-col :span="8" class="col_border">单价金额： {{item.Price}}</el-col>
                        </el-row>
                        <el-row class="border_bottom">
                          <el-col :span="8" class="col_border">消耗周期：{{item.ConsumeCycle}}天/{{item.CycleLimitAmount}}次</el-col>
                          <el-col :span="8" class="col_border">最进消耗周期内消耗次数： {{item.ConsumeCycleAmount}}</el-col>
                          <el-col :span="8" class="col_border dis_flex flex_dir_row">
                            欠款：
                            <div class="color_red">{{item.ArrearAmount}}</div>
                          </el-col>
                        </el-row>
                        <el-row class>
                          <el-col :span="12" class="col_border">
                            购买时间：
                            <span class="font_color">{{item.BuyDate}}</span>
                          </el-col>
                          <el-col :span="12" class="col_border" style="border-right:0px">
                            有效期至：
                            <span class="font_color">{{item.ValidDayName}}</span>
                          </el-col>
                          <el-col :span="8" class="col_border">卡金额： {{item.TotalAmount}}</el-col>
                        </el-row>
                      </el-row>
                    </el-col>
                  </el-card>-->
                </el-scrollbar>
              </el-tab-pane>
            </el-tabs>
          </el-col>
          <el-col :span="14" class="project_right">
            <el-container style="height:62vh">
              <el-main class="border_bottom">
                <el-scrollbar class="el-scrollbar_height">
                  <!--储值卡-->
                  <div v-if="Savingcard.length >0">
                    <el-row class="row_header border_bottom">
                      <el-col :span="24">储值卡</el-col>
                    </el-row>
                    <el-row class="border_bottom" v-for="(item,index) in Savingcard" :key="index+'sav'">
                      <el-col :span="24" class="pad_10">
                        <el-col :span="24" style="line-height:32px">
                          <el-col :span="9">
                            {{item.Name}}
                            <span v-if="item.Alias">({{item.Alias}})</span>
                          </el-col>
                          <el-col :span="5">
                            <el-input v-model="item.ValidDayName" class="color_333 itemWidth" size="small" :disabled="true"></el-input>
                          </el-col>
                          <el-col :span="5">
                            <el-date-picker class="item_date_picker_Width" :disabled="item.IsEverlasting" value-format="yyyy-MM-dd" v-model="item.NewValidDayName" size="small" placeholder="选择日期"></el-date-picker>
                          </el-col>
                          <el-col :span="3">
                            <el-checkbox style="line-height:30px" v-model="item.IsEverlasting" :disabled="item.NewValidDayName != null?true:false" class="marlt_15">永久有效</el-checkbox>
                          </el-col>
                          <el-col :span="2" class="text_right">
                            <el-button type="danger" icon="el-icon-delete" circle size="mini" @click="removeCard(index,Savingcard)"></el-button>
                          </el-col>
                        </el-col>
                      </el-col>
                    </el-row>
                  </div>
                  <!--时效卡-->
                  <div v-if="TimeCard.length>0">
                    <el-row class="row_header border_bottom">
                      <el-col :span="24">时效卡</el-col>
                    </el-row>
                    <el-row class="border_bottom" v-for="(item,index) in TimeCard" :key="index+'time'">
                      <el-col :span="24" class="pad_10">
                        <el-col :span="24" style="line-height:32px">
                          <el-col :span="9">
                            {{item.Name}}
                            <span v-if="item.Alias">({{item.Alias}})</span>
                          </el-col>
                          <el-col :span="5">
                            <el-input v-model="item.ValidDayName" class="color_333 itemWidth" value="2020-09-29" size="small" :disabled="true"></el-input>
                          </el-col>
                          <el-col :span="5">
                            <el-date-picker class="item_date_picker_Width" :disabled="item.IsEverlasting" value-format="yyyy-MM-dd" v-model="item.NewValidDayName" size="small" align="right" placeholder="选择日期"></el-date-picker>
                          </el-col>
                          <el-col :span="3">
                            <el-checkbox style="line-height:30px" v-model="item.IsEverlasting" :disabled="item.NewValidDayName != null?true:false" class="marlt_15">永久有效</el-checkbox>
                          </el-col>
                          <el-col :span="2" class="text_right">
                            <el-button type="danger" icon="el-icon-delete" circle size="mini" @click="removeCard(index,TimeCard)"></el-button>
                          </el-col>
                        </el-col>
                      </el-col>
                    </el-row>
                  </div>
                  <!--通用次卡-->
                  <div v-if="GeneralCard.length>0">
                    <el-row class="row_header border_bottom">
                      <el-col :span="24">通用次卡</el-col>
                    </el-row>
                    <el-row class="border_bottom" v-for="(item,index) in GeneralCard" :key="index+'general'">
                      <el-col :span="24" class="pad_10">
                        <el-col :span="24" style="line-height:32px">
                          <el-col :span="9">
                            {{item.Name}}
                            <span v-if="item.Alias">{{item.Alias}}</span>
                          </el-col>
                          <el-col :span="5">
                            <el-input v-model="item.ValidDayName" class="color_333 itemWidth" value="2020-09-29" size="small" :disabled="true"></el-input>
                          </el-col>
                          <el-col :span="5">
                            <el-date-picker class="item_date_picker_Width" :disabled="item.IsEverlasting" value-format="yyyy-MM-dd" v-model="item.NewValidDayName" size="small" align="right" placeholder="选择日期"></el-date-picker>
                          </el-col>
                          <el-col :span="3">
                            <el-checkbox style="line-height:30px" v-model="item.IsEverlasting" :disabled="item.NewValidDayName != null?true:false" class="marlt_15">永久有效</el-checkbox>
                          </el-col>
                          <el-col :span="2" class="text_right">
                            <el-button type="danger" icon="el-icon-delete" circle size="mini" @click="removeCard(index,GeneralCard)"></el-button>
                          </el-col>
                        </el-col>
                      </el-col>
                    </el-row>
                  </div>
                  <!--套餐卡-->
                  <div v-if="PackageCard.length>0">
                    <el-row class="row_header border_bottom">
                      <el-col :span="24">套餐卡</el-col>
                    </el-row>
                    <el-row class="border_bottom" v-for="(item,index) in PackageCard" :key="index +'pack'">
                      <el-col :span="24" class="pad_10">
                        <el-col :span="24" style="line-height:32px">
                          <el-col :span="9">
                            {{item.Name}}
                            <span v-if="item.Alias">({{item.Alias}})</span>
                          </el-col>
                          <el-col :span="5">
                            <el-input v-model="item.ValidDayName" class="color_333 itemWidth" value="2020-09-29" size="small" :disabled="true"></el-input>
                          </el-col>
                          <el-col :span="5">
                            <el-date-picker class="item_date_picker_Width" :disabled="item.IsEverlasting" value-format="yyyy-MM-dd" v-model="item.NewValidDayName" size="small" placeholder="选择日期"></el-date-picker>
                          </el-col>
                          <el-col :span="3">
                            <el-checkbox style="line-height:30px" v-model="item.IsEverlasting" :disabled="item.NewValidDayName != null?true:false" class="marlt_15">永久有效</el-checkbox>
                          </el-col>
                          <el-col :span="2" class="text_right">
                            <el-button type="danger" icon="el-icon-delete" circle size="mini" @click="removeCard(index,PackageCard)"></el-button>
                          </el-col>
                        </el-col>
                      </el-col>
                    </el-row>
                  </div>
                </el-scrollbar>
              </el-main>
              <el-footer>
                <el-row type="flex" align="middle" justify="space-between">
                  <el-col :span="2">备注信息</el-col>
                  <el-col :span="13" class="martp_5">
                    <el-input type="textarea" :rows="1" v-model="Remark" placeholder="请输入备注信息"></el-input>
                  </el-col>
                  <el-col :span="9" class="text_right button_margin">
                    <el-button size="small" @click="GoBack">取消</el-button>
                    <!-- <el-button type="primary" size="small" @click="SubmitPostponeBillSave(10)">保存草稿</el-button> -->
                    <el-button type="primary" size="small" @click="SubmitPostponeBillSave(20)">提交申请</el-button>
                  </el-col>
                </el-row>
              </el-footer>
            </el-container>
          </el-col>
        </el-row>
      </div>
    </el-dialog>

    <!-- 详情 -->
    <el-dialog :visible.sync="applyDetailVisible" width="1100px">
      <span slot="title">
        <span>延期详情</span>
      </span>
      <!-- //订单详情弹窗 -->
      <div style="height:60vh;font-size:13px !important">
        <el-scrollbar class="el-scrollbar_height">
          <div style="margin-right:10px" class="marbm_10">
            <el-form label-width="100px" size="small" class="postponeApply">
              <div class="tip" style="margin-top:0">延期信息</div>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="单据号:">{{ApplyDetail.ID}}</el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="申请人:">{{ApplyDetail.CreatedByName}}</el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="申请时间:">{{ApplyDetail.CreatedOn}}</el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="客户信息:">{{ApplyDetail.CustomerName}} ({{ApplyDetail.PhoneNumber}})</el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="审批状态:">{{ApplyDetail.ApprovalStatus == 10?"草稿":ApplyDetail.ApprovalStatus == 20?"待审批" :ApplyDetail.ApprovalStatus == 30?"已同意":ApplyDetail.ApprovalStatus ==40 ?"驳回":'撤销'}}</el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="备注信息:">{{ApplyDetail.PostponeRemark}}</el-form-item>
                </el-col>
              </el-row>
              <el-row v-if="IsShow">
                <el-col :span="8">
                  <el-form-item label="审批人:">{{ApplyDetail.ApprovalByName}}</el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="审批时间:">{{ApplyDetail.ApprovalOn}}</el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="审批说明:">{{ApplyDetail.ApprovalRemark}}</el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
          <div class="tip">延期明细</div>
          <div class="border_left border_right border_top">
            <el-row class="row_Detalheader border_bottom" v-if="ApplyDetail.SavingCard!=undefined && ApplyDetail.SavingCard.length >0">
              <el-col :span="10">储值卡</el-col>
              <el-col :span="7">原失效日期</el-col>
              <el-col :span="7">新失效日期</el-col>
            </el-row>
            <el-row v-for="item in ApplyDetail.SavingCard" :key="item.SavingCardAccountID+'sve'" class="border_bottom">
              <el-col :span="24" class="pad_10">
                <el-col :span="24">
                  <el-col :span="10">
                    {{item.Name}}
                    <span v-if="item.Alias">({{item.Alias}})</span>
                  </el-col>
                  <el-col :span="7">{{item.ValidDayName}}</el-col>
                  <el-col :span="7">{{item.NewDayName}}</el-col>
                </el-col>
              </el-col>
            </el-row>

            <el-row class="row_Detalheader border_bottom" v-if="ApplyDetail.GeneralCard!=undefined && ApplyDetail.GeneralCard.length>0">
              <el-col :span="10">通用次卡</el-col>
              <el-col :span="7">原失效日期</el-col>
              <el-col :span="7">新失效日期</el-col>
            </el-row>
            <el-row v-for="item in ApplyDetail.GeneralCard" :key="item.GeneralCardAccountID+'gnreal'" class="border_bottom">
              <el-col :span="24" class="pad_10">
                <el-col :span="24">
                  <el-col :span="10">
                    {{item.Name}}
                    <span v-if="item.Alias">({{item.Alias}})</span>
                  </el-col>
                  <el-col :span="7">{{item.ValidDayName}}</el-col>
                  <el-col :span="7">{{item.NewDayName}}</el-col>
                </el-col>
              </el-col>
            </el-row>

            <el-row class="row_Detalheader" v-if="ApplyDetail.TimeCard!=undefined && ApplyDetail.TimeCard.length >0">
              <el-col :span="10">时效卡</el-col>
              <el-col :span="7">原失效日期</el-col>
              <el-col :span="7">新失效日期</el-col>
            </el-row>
            <el-row v-for="item in ApplyDetail.TimeCard" :key="item.TimeCardAccountID+'timecard'" class="border_bottom">
              <el-col :span="24" class="pad_10">
                <el-col :span="24">
                  <el-col :span="10">
                    {{item.Name}}
                    <span v-if="item.Alias">({{item.Alias}})</span>
                  </el-col>
                  <el-col :span="7">{{item.ValidDayName}}</el-col>
                  <el-col :span="7">{{item.NewDayName}}</el-col>
                </el-col>
              </el-col>
            </el-row>

            <el-row class="row_Detalheader border_bottom" v-if="ApplyDetail.PackageCard!= undefined && ApplyDetail.PackageCard.length>0">
              <el-col :span="10">套餐卡</el-col>
              <el-col :span="7">原失效日期</el-col>
              <el-col :span="7">新失效日期</el-col>
            </el-row>
            <el-row v-for="item in ApplyDetail.PackageCard" :key="item.PackageCardAccountID+'package'" class="border_bottom">
              <el-col :span="24" class="pad_10">
                <el-col :span="24">
                  <el-col :span="10">
                    {{item.Name}}
                    <span v-if="item.Alias">({{item.Alias}})</span>
                  </el-col>
                  <el-col :span="7">{{item.ValidDayName}}</el-col>
                  <el-col :span="7">{{item.NewDayName}}</el-col>
                </el-col>
              </el-col>
            </el-row>
          </div>
        </el-scrollbar>
      </div>
      <span slot="footer" class="dialog-footer text_center">
        <el-button v-if="IsDraft" type="danger" size="small" @click="Cancellation">撤销</el-button>
        <el-button v-if="IsDraft" type="primary" size="small" @click="reviseDraft">修改</el-button>
        <el-button v-if="IsApprove" type="danger" size="small" @click="withdrawApplyClick" :loading="cancelSaleBillLoading">撤销申请</el-button>
      </span>
    </el-dialog>

    <!--  撤销审批 -->
    <el-dialog :visible.sync="withdrawDialog" width="350px">
      <div class="dis_flex flex_x_center flex_y_center flex_dir_column">
        <el-row>
          <i class="el-icon-document" style="font-size: 80px ;color: #999"></i>
        </el-row>
        <el-row>
          <el-col class="color_red font_24 martp_15">是否撤销申请</el-col>
        </el-row>
        <el-row>
          <el-col class="martp_15">是否撤销申请，撤销后订单将作废</el-col>
        </el-row>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="withdrawDialog = false" size="small">取 消</el-button>
        <el-button type="primary" @click="confrimWithdrawApplyClick" size="small">确定撤销</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import API from "@/api/iBeauty/PostponeTransfer/postponeApply";
import date from "@/components/js/date";

export default {
  name: "PostponeApply",
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      loading: false,
      PostponebillID: "",
      Name: "",
      SearchData: "",
      isAllowSell: "",
      ApplyDetail: "",
      PostponeBillID: "",
      EndDate: "",
      StartDate: "",
      // pickerOptions: {
      //   disabledDate(time) {
      //     return (
      //        time.getTime() < Date.now()
      //     );
      //   },
      // },
      paginations: {
        page: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next,jumper", // 翻页属性
      },
      Remark: "",
      tableData: [],
      SavingCardList: [],
      GeneralCardList: [],
      TimeCardList: [],
      PackageCardList: [],
      Savingcard: [],
      GeneralCard: [],
      TimeCard: [],
      PackageCard: [],
      IsShow: true,
      IsDraft: false,
      IsApprove: false,
      addApplyVisible: false,
      applyDetailVisible: false,
      withdrawDialog: false,
      customerAlias: "",
      NewValidDate: false,
      customerName: "",
      customerID: null,
      customerFullName: "",
      customerPhoneNumber: "",
      tabPane: "0",
      currentDate: new Date(),
      createTime: "",
      cancelSaleBillLoading: false,
    };
  },
  methods: {
    handleCurrentChange(page) {
      this.paginations.page = page;
      this.getPostponeBillList();
    },

    handleSearch: function () {
      var that = this;
      that.paginations.page = 1;
      that.getPostponeBillList();
    },
    //延期申请列表
    getPostponeBillList() {
      var that = this;
      if (that.SearchData == null || that.SearchData == "") {
        that.StartDate = "";
        that.EndDate = "";
      } else {
        that.StartDate = that.SearchData[0];
        that.EndDate = that.SearchData[1];
      }
      var params = {
        PageNum: that.paginations.page,
        PostponeBillID: that.PostponebillID,
        Name: that.Name,
        StartDate: that.StartDate,
        EndDate: that.EndDate,
        ApprovalStatus: that.isAllowSell,
      };
      API.PostponeBillList(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.tableData = res.List;
            that.paginations.total = res.Total;
            that.paginations.page_size = res.PageSize;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },

    /** 添加延期  */
    showAddApplyDialog() {
      var that = this;
      that.PostponeBillID = "";
      // if (that.customerName == "" || that.customerName == null) {
      //   that.$message.error({
      //     message: "请选择顾客",
      //     duration: 2000,
      //     customClass: "zZindex",
      //   });
      // }
      that.Remark = "";
      this.customerID = null;
      this.customerFullName = "";
      this.customerPhoneNumber = "";
      this.customerName = "";
      (this.SavingCardList = []),
        (this.GeneralCardList = []),
        (this.TimeCardList = []),
        (this.PackageCardList = []),
        (this.Savingcard = []),
        (this.TimeCard = []),
        (this.GeneralCard = []),
        (this.PackageCard = []),
        (this.customerAlias = "");
      that.addApplyVisible = true;
    },

    showApplyDetailDialog(row) {
      var that = this;
      that.loading = true;

      that.PostponeBillID = row.ID;
      var params = {
        ID: that.PostponeBillID,
      };
      API.PostponeBillDeatail(params)
        .then((res) => {
          if (res.StateCode == 200) {
            res.Data.SavingCard.forEach((save) => {
              save.IsEverlasting = null;
              save.NewDayName = save.NewValidDayName;
            });
            res.Data.TimeCard.forEach((time) => {
              time.IsEverlasting = null;
              time.NewDayName = time.NewValidDayName;
            });
            res.Data.GeneralCard.forEach((GeneralCard) => {
              GeneralCard.IsEverlasting = null;
              GeneralCard.NewDayName = GeneralCard.NewValidDayName;
            });
            res.Data.PackageCard.forEach((PackageCard) => {
              PackageCard.IsEverlasting = null;
              PackageCard.NewDayName = PackageCard.NewValidDayName;
            });
            that.ApplyDetail = res.Data;
            if (
              that.ApplyDetail.ApprovalStatus == 10 ||
              that.ApplyDetail.ApprovalStatus == 20 ||
              that.ApplyDetail.ApprovalStatus == 50
            ) {
              that.IsShow = false;
            } else {
              that.IsShow = true;
            }
            if (that.ApplyDetail.ApprovalStatus == 10) {
              that.IsDraft = true;
              that.IsApprove = false;
            } else if (that.ApplyDetail.ApprovalStatus == 20) {
              that.IsDraft = false;
              that.IsApprove = true;
            } else {
              that.IsApprove = false;
              that.IsDraft = false;
            }
            that.applyDetailVisible = true;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    /** 顾客    */
    saleCustomerData: function (queryString, cb) {
      var that = this;
      that.loading = true;
      var params = {
        Name: queryString ? queryString : "",
      };
      API.getSaleCustomer(params)
        .then((res) => {
          if (res.StateCode == 200) {
            cb(res.Data);
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    /**  选择顾客  */
    handleCustomerSelect(item) {
      var that = this;
      if (item.ID != undefined) {
        that.customerID = item.ID;
        that.customerFullName = item.Name;
        that.tabPane = "";
        that.customerPhoneNumber = item.PhoneNumber;
        that.customerName = item.Name + "【" + item.PhoneNumber + "】";
        that.PostPoneSavingCardList();
        that.PostponeGeneralCradList();
        that.PostponeTimeCardList();
        that.PostponePackageCardList();
      }
    },

    searchGoodsClick() {
      var that = this;
      if (that.customerID == null || that.customerID == "") {
        that.$message.error({
          message: "顾客编号不能为空",
          duration: 2000,
        });
        return;
      }
      that.tabPane = "";
      that.PostPoneSavingCardList();
      that.PostponePackageCardList();
      that.PostponeTimeCardList();
      that.PostponeGeneralCradList();
    },
    removeCustomer() {
      this.customerID = null;
      this.customerFullName = "";
      this.customerPhoneNumber = "";
      this.customerName = "";
      (this.SavingCardList = []),
        (this.GeneralCardList = []),
        (this.TimeCardList = []),
        (this.PackageCardList = []),
        (this.Savingcard = []),
        (this.TimeCard = []),
        (this.GeneralCard = []),
        (this.PackageCard = []),
        (this.customerAlias = "");
    },

    /**  确认撤销  */
    withdrawApplyClick() {
      var that = this;
      that.cancelSaleBillLoading = true;
      this.$confirm("此操作将取消当前订单, 是否继续?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          var params = {
            ID: that.PostponeBillID,
          };
          API.PostponeBillRevoke(params)
            .then((res) => {
              if (res.StateCode == 200) {
                that.$message.success({
                  message: res.Message,
                  duration: 2000,
                });
                that.applyDetailVisible = false;
                that.getPostponeBillList();
              } else {
                that.$message.error({
                  message: res.Message,
                  duration: 2000,
                });
              }
            })
            .finally(function () {
              that.cancelSaleBillLoading = false;
            });
        })
        .catch(() => {
          that.cancelSaleBillLoading = false;
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    /**  撤销申请  */
    confrimWithdrawApplyClick() {
      var that = this;
      var params = {
        ID: that.PostponeBillID,
      };
      API.PostponeBillRevoke(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success({
              message: res.Message,
              duration: 2000,
            });
            that.applyDetailVisible = false;
            that.getPostponeBillList();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
      that.withdrawDialog = false;
    },

    //延期储值卡存量列表
    PostPoneSavingCardList() {
      var that = this;
      var params = {
        CustomerID: that.customerID,
        Name: that.customerAlias,
      };
      API.PostponeSavingCardList(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.SavingCardList = res.Data;
            if (that.tabPane == "" && res.Data.length > 0) {
              that.tabPane = "0";
            }
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    //延期通用次卡存量列表
    PostponeGeneralCradList() {
      var that = this;
      var params = {
        CustomerID: that.customerID,
        Name: that.customerAlias,
      };
      API.PostponeGeneralCradList(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.GeneralCardList = res.Data;
            if (that.tabPane == "" && res.Data.length > 0) {
              that.tabPane = "3";
            }
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    //延期时效卡存量列表
    PostponeTimeCardList() {
      var that = this;
      var params = {
        CustomerID: that.customerID,
        Name: that.customerAlias,
      };
      API.PostponeTimeCardList(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.TimeCardList = res.Data;
            if (that.tabPane == "" && res.Data.length > 0) {
              that.tabPane = "2";
            }
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    //延期套餐卡存量列表
    PostponePackageCardList() {
      var that = this;
      var params = {
        CustomerID: that.customerID,
        Name: that.customerAlias,
      };
      API.PostponePackageCardList(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.PackageCardList = res.Data;
            if (that.tabPane == "" && res.Data.length > 0) {
              that.tabPane = "1";
            }
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },

    //添加卡
    clickItemConsume(item, type) {
      var that = this;
      var Isinsert = false; //判断不可以重复添加
      if (type == 2) {
        that.Savingcard.forEach((card) => {
          if (item.ID == card.ID) {
            that.$message.error({
              message: "储值卡已存在",
              duration: 2000,
            });
            Isinsert = true;
          }
        });
        if (!Isinsert) {
          that.Savingcard.push(item);
        }
      } else if (type == 3) {
        that.PackageCard.forEach((card) => {
          if (item.ID == card.ID) {
            that.$message.error({
              message: "套餐卡已存在",
              duration: 2000,
            });
            Isinsert = true;
          }
        });
        if (!Isinsert) {
          that.PackageCard.push(item);
        }
      } else if (type == 4) {
        that.TimeCard.forEach((card) => {
          if (item.ID == card.ID) {
            that.$message.error({
              message: "时效卡已存在",
              duration: 2000,
            });
            Isinsert = true;
          }
        });
        if (!Isinsert) {
          that.TimeCard.push(item);
        }
      } else if (type == 5) {
        that.GeneralCard.forEach((card) => {
          if (item.ID == card.ID) {
            that.$message.error({
              message: "通用次卡已存在",
              duration: 2000,
            });
            Isinsert = true;
          }
        });
        if (!Isinsert) {
          that.GeneralCard.push(item);
        }
      }
    },

    //延期申请删除card
    removeCard(index, List) {
      List.splice(index, 1);
    },
    //提交延期申请

    PostponeBillSave(type) {
      var that = this;
      var GeneralCard = [];
      var TimeCard = [];
      var SavingCard = [];
      var PackageCard = [];
      var IsError = true;
      var params = {
        ID: that.PostponeBillID,
        CustomerID: that.customerID,
        Remark: that.Remark,
        ApprovalStatus: type,
        GeneralCard: GeneralCard,
        TimeCard: TimeCard,
        SavingCard: SavingCard,
        PackageCard: PackageCard,
      };
      that.Savingcard.forEach(function (item) {
        if (
          (item.NewValidDayName == undefined &&
            item.IsEverlasting == undefined) ||
          (item.NewValidDayName == null && item.IsEverlasting == false)
        ) {
          IsError = false;
        } else if (
          item.IsEverlasting == undefined ||
          item.IsEverlasting == null ||
          item.IsEverlasting == ""
        ) {
          item.IsEverlasting = false;
        } else if (item.IsEverlasting) {
          item.NewValidDayName = null;
        }
        SavingCard.push({
          SavingCardAccountID: item.ID,
          IsEverlasting: item.IsEverlasting,
          NewValidDate: item.NewValidDayName,
        });
      });
      that.GeneralCard.forEach(function (item) {
        if (
          (item.NewValidDayName == undefined &&
            item.IsEverlasting == undefined) ||
          (item.NewValidDayName == null && item.IsEverlasting == false)
        ) {
          IsError = false;
        } else if (
          item.IsEverlasting == undefined ||
          item.IsEverlasting == null ||
          item.IsEverlasting == ""
        ) {
          item.IsEverlasting = false;
        } else if (item.IsEverlasting) {
          item.NewValidDayName = null;
        }
        GeneralCard.push({
          GeneralCardAccountID: item.ID,
          IsEverlasting: item.IsEverlasting,
          NewValidDate: item.NewValidDayName,
        });
      });
      that.TimeCard.forEach(function (item) {
        if (
          (item.NewValidDayName == undefined &&
            item.IsEverlasting == undefined) ||
          (item.NewValidDayName == null && item.IsEverlasting == false)
        ) {
          IsError = false;
        } else if (
          item.IsEverlasting == undefined ||
          item.IsEverlasting == null ||
          item.IsEverlasting == ""
        ) {
          item.IsEverlasting = false;
        } else if (item.IsEverlasting) {
          item.NewValidDayName = null;
        }
        TimeCard.push({
          TimeCardAccountID: item.ID,
          IsEverlasting: item.IsEverlasting,
          NewValidDate: item.NewValidDayName,
        });
      });
      that.PackageCard.forEach(function (item) {
        if (
          (item.NewValidDayName == undefined &&
            item.IsEverlasting == undefined) ||
          (item.NewValidDayName == null && item.IsEverlasting == false)
        ) {
          IsError = false;
        } else if (
          item.IsEverlasting == undefined ||
          item.IsEverlasting == null ||
          item.IsEverlasting == ""
        ) {
          item.IsEverlasting = false;
        } else if (item.IsEverlasting) {
          item.NewValidDayName = null;
        }

        PackageCard.push({
          PackageCardAccountID: item.ID,
          IsEverlasting: item.IsEverlasting,
          NewValidDate: item.NewValidDayName,
        });
      });
      if (
        GeneralCard.length == 0 &&
        TimeCard.length == 0 &&
        SavingCard.length == 0 &&
        PackageCard.length == 0
      ) {
        that.$message.error({
          message: "卡不能为空",
          duration: 2000,
          customClass: "zZindex",
        });
      } else if (!IsError) {
        that.$message.error({
          message: "请选择新失效日期",
          duration: 2000,
        });
      } else {
        API.SubmitPostponeBill(params)
          .then((res) => {
            if (res.StateCode == 200) {
              that.$message.success({
                message: "提交成功",
                duration: 2000,
                customClass: "zZindex",
              });
              that.addApplyVisible = false;
              that.applyDetailVisible = false;
              that.getPostponeBillList();
            } else {
              that.$message.success({
                message: res.Message,
                duration: 2000,
                customClass: "zZindex",
              });
            }
          })
          .finally(function () {
            that.loading = false;
          });
      }
    },
    //返回
    GoBack() {
      this.addApplyVisible = false;
    },
    //草稿作废
    Cancellation() {
      var that = this;
      that.withdrawApplyClick();
      // this.confrimWithdrawApplyClick()
    },
    //草稿修改
    reviseDraft() {
      var that = this;
      that.Remark = "";
      that.customerID = that.ApplyDetail.CustomerID;
      that.customerName =
        that.ApplyDetail.CustomerName +
        "【" +
        that.ApplyDetail.PhoneNumber +
        "】";
      that.PostPoneSavingCardList();
      that.PostponeGeneralCradList();
      that.PostponeTimeCardList();
      that.PostponePackageCardList();
      that.customerAlias = "";
      that.ApplyDetail.SavingCard.forEach((card) => {
        card.ID = card.SavingCardAccountID;
        if (card.NewValidDayName == "永久有效") {
          (card.NewValidDayName = null), (card.IsEverlasting = true);
        }
      });
      that.Savingcard = that.ApplyDetail.SavingCard;
      that.ApplyDetail.GeneralCard.forEach((card) => {
        card.ID = card.GeneralCardAccountID;
        if (card.NewValidDayName == "永久有效") {
          (card.NewValidDayName = null), (card.IsEverlasting = true);
        }
      });
      that.GeneralCard = that.ApplyDetail.GeneralCard;
      that.ApplyDetail.TimeCard.forEach((card) => {
        card.ID = card.TimeCardAccountID;
        if (card.NewValidDayName == "永久有效") {
          (card.NewValidDayName = null), (card.IsEverlasting = true);
        }
      });
      that.TimeCard = that.ApplyDetail.TimeCard;
      that.ApplyDetail.PackageCard.forEach((card) => {
        card.ID = card.PackageCardAccountID;
        if (card.NewValidDayName == "永久有效") {
          (card.NewValidDayName = null), (card.IsEverlasting = true);
        }
      });
      that.PackageCard = that.ApplyDetail.PackageCard;
      that.addApplyVisible = true;
    },
    //提交申请
    SubmitPostponeBillSave(type) {
      var that = this;
      that.PostponeBillSave(type);
    },
  },
  mounted() {
    var that = this;
    if (localStorage.getItem("access-user")) {
      that.userName = JSON.parse(
        localStorage.getItem("access-user")
      ).EmployeeName;
      that.entityName = JSON.parse(
        localStorage.getItem("access-user")
      ).EntityName;
    }
    // var date = new Date(),
    //   y = date.getFullYear(),
    //   m = date.getMonth();

    // that.SearchData = [new Date(y, m, 1), new Date()];
    that.getPostponeBillList();
  },
};
</script>

<style  lang="scss">
.PostponeApply {
  .project_content {
    font-size: 13px;
    height: 100%;
    .project_left {
      border-right: 1px solid #eee;
      height: 100%;
      color: #303133;
      .el-tabs {
        .el-tabs__header {
          margin: 0;
          .el-tabs__nav-scroll {
            height: 100%;
          }
        }
        .el-tabs__content {
          height: calc(100% - 40px);
          .el-tab-pane {
            height: 100%;
            .col_border {
              border-right: 1px solid #eee;
              padding: 5px 10px;
              color: #666;
              font-size: 13px;
            }
          }
        }
      }
    }
    .project_right {
      height: 100%;
      .el-icon-sort {
        color: #666;
        font-size: 20px;
        transform: rotate(90deg);
      }
      .el-main {
        padding: 0;

        .row_header {
          background-color: #ecf8ff;
          padding: 10px;
        }
        .employee_num {
          width: 90px;
          .el-input-group__append {
            padding: 0 10px;
          }
        }
        .back_project_col {
          background-color: #fafafa;
        }
      }
      .el-footer {
        height: initial !important;
        padding: 10px;
      }

      .item_date_picker_Width {
        width: 100%;
      }
      .itemWidth {
        width: 65%;
        .el-input__inner {
          width: 100px;
        }
      }
    }
  }
  .row_Detalheader {
    background-color: #ecf8ff;
    padding: 10px;
  }
  .el-scrollbar_height {
    height: 100%;
    /deep/.el-scrollbar__wrap {
      overflow-x: hidden;
    }
    /deep/.el-scrollbar_bar {
      left: 0px;
    }
  }
  .postponeGoods {
    .goods-item {
      line-height: 36px;
      font-size: 12px;
      margin-left: 20px;
      margin-right: 5px;
    }
    .goods-lable {
      color: #606266;
    }

    .el-card__header {
      padding: 10px 20px;
      background-color: #f5f7fa;
    }
  }

  .dialog_bill_detail {
    background-color: #ecf8ff;
    padding: 15px;
    border-radius: 5px;
  }

  .button_margin {
    .el-button {
      margin-top: 5px;
    }
  }
  .postponeApply {
    .el-form-item__label {
      font-size: 13px !important;
    }
    .el-form-item__content {
      font-size: 13px !important;
    }
    .el-form-item {
      margin-bottom: 0px;
    }
  }
  .font_color {
    color: #303133;
  }
}
.customer-autocomplete {
  li {
    line-height: normal;
    padding: 7px;

    .name {
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .info {
      font-size: 12px;
      color: #b4b4b4;
    }
    .highlighted .info {
      color: #ddd;
    }
  }
}
</style>