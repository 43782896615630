/**
 * Created by preference on 2020/07/17
 *  zmx 
 */

import * as API from '@/api/index'
export default {
/**  查找会员 */
getSaleCustomer: params => {
  return API.POST('api/saleCustomer/customer', params)
},

// 延期申请列表
PostponeBillList: params =>{
  return API.POST('api/postponeBill/list',params)
},

//延期申请详情
PostponeBillDeatail: params =>{
  return API.POST('api/postponeBill/info', params)
},

//延期申请撤销
PostponeBillRevoke: params =>{
  return API.POST('api/postponeBill/revoke',params)
},

//延期储值卡存量列表
PostponeSavingCardList: params =>{
  return API.POST('api/postponeAccount/savingCardAccount',params)
},

//延期通用次卡存量列表
PostponeGeneralCradList: params =>{
  return API.POST('api/postponeAccount/generalCardAccount',params)
},

//延期时效卡存量列表
PostponeTimeCardList:params =>{
  return API.POST("api/postponeAccount/timeCardAccount",params)
},

//延期套餐卡存量列表
PostponePackageCardList: params =>{
  return API.POST('api/postponeAccount/packageCardAccount',params)
},

//延期审批列表
PostponeBillApprovalList: params =>{
  return API.POST('api/postponeBill/approvalList',params)
},
//提交审批
SubmitPostponeBill: params =>{
  return API.POST("api/postponeBill/create", params)
},
//延期审批
ExtendPostponeBill: params =>{
  return API.POST('api/postponeBill/approval',params)
},

}